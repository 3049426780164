h1,
h2 {
  margin-top: 0;
  margin-bottom: 20px;
}
h1 {
  font-size: 24px;
  line-height: 1.4;
}
h2 {
  font-size: 24px;
  line-height: 1.4;
  /* border-bottom: 1px solid #ddd; */
  padding-top: 10px;
}
h3 {
  font-weight: bold;
}
p {
  margin: 0 0 15px;
  padding: 0;
  font-size: 16px;
  line-height: 1.7;
}
ul {
  margin: 0 0 15px 20px;
  padding: 0;
  list-style-type: disc;
  font-size: 16px;
}
li {
  margin: 0 0 10px;
  padding: 0;
  line-height: 1.7;
}
strong {
  font-weight: bold;
}
